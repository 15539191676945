
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
export default {
   props: ["data"],
  setup(props, { emit }) {
    const customer: any = ref(props.data);
    const store = useStore();
    store.dispatch(Actions.CUSTOMER_LIST).then((x) => {

      console.log(x)
    });
    const customerList = computed(
      () => store.state.CustomerModule.customer.value || []
    );

    const onChange = (args) => {
      emit("changeCustomer",args);
    };
    return { close, customer, store, customerList, onChange };
  },
};

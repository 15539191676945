
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "nexera-upload",
  props: {
    title: { type: String },
    width: { type: String, default: "50vw" },
    isDisabled: { type: Boolean },
    supplierId: { type: Number },
  },
  components: {},
  setup(props, { emit }) {
    const fileUpload = ref<HTMLElement | null>(null);
    const onUpload = (event) => {
      emit("data", JSON.parse(event.xhr.responseText));
    };

   

    const myUploader = (event) => {
      //event.files == files to upload      
      //event.files[0].supplierId = props.supplierId; // set("supplierId",5);
      emit("upload", event);

      //emit("data", JSON.parse(event.xhr.responseText));
      if (fileUpload.value) {
        //fileUpload.value.files = [];
      }
    };
    const onChangeFileUpload = (event) => {
      if (!fileUpload.value) {
        return;
      }
       var files = event.target.files || event.dataTransfer.files;
      if (!files.length)
        return;

      emit("upload",files);
    };

    return { onUpload, myUploader, fileUpload,onChangeFileUpload };
  },
});

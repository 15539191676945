import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    type: "file",
    class: "form-control",
    id: "customFile",
    accept: "image/*,application/pdf",
    multiple: "true",
    ref: "fileUpload",
    name: "files[]",
    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChangeFileUpload($event)))
  }, null, 544))
}

import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
export default {
  props: ["data"],
  setup(props, { emit }) {
    const orderStatus = ref([      
      { id: 1, name: "Açık" },
      { id: 2, name: "Kapalı" },
    ]);
    const statusModel: any = ref(
      orderStatus.value.find((f) => f.id == props.data.isActive)
    );

    const store = useStore();
    const statusList = computed(() => orderStatus.value || []);

    const onChange = (args) => {
      emit("changeStatus", args);
    };
    return { close, statusModel, store, statusList, onChange, props };
  },
};
